import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDCHRIYwCqzsPi2e_wETZ17pPmDAgXFvaI",
  authDomain: "adidasbooking5.firebaseapp.com",
  databaseURL: "https://adidasbooking5.firebaseio.com",
  projectId: "adidasbooking5",
  storageBucket: "adidasbooking5.appspot.com",
  messagingSenderId: "912851114808",
  appId: "1:912851114808:web:c687bb35fb596a36833fcd",
  measurementId: "G-42JGM5PT2P"
});

const database = firebaseApp.database();

export const getServerPass = async () => {
  let passRef = database.ref(`/globalSettings/public/webSecurity/greenGrass`);
  var serverPass;
  await passRef.once("value", snapshot => {
    serverPass = snapshot.val() || "None Found";
  });
  return serverPass;
};

export const getDefaultSeason = async () => {
  let defaultSeasonRef = database.ref(`/globalSettings/public/defaultSeason`);
  let defaultSeason;
  await defaultSeasonRef.once("value", snapshot => {
    defaultSeason = snapshot.val() || "None Found";
  });
  return defaultSeason;
};

export const getSeasons = async () => {
  let seasonsRef = database.ref(`/globalSettings/public/seasons`);
  let seasons;
  await seasonsRef.once("value", snapshot => {
    seasons = snapshot.val() || {};
  });
  return seasons;
};

export const getArticlesFor = async (season, subCategory) => {
  let fullSeasonName = season.concat("_", subCategory);
  let articlesRef = database.ref(`articles/${fullSeasonName}`);
  let articles;
  await articlesRef.once("value", snapshot => {
    articles = snapshot.val() || {};
  });
  return articles;
};

export default database;
