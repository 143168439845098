import React from "react";
import { netPrice } from "../helpers";

class ArticleBox extends React.Component {
  showModal = () => {
    this.props.updateHighResArticle(this.props.articleInfo["articleNumber"]);
  };

  render() {
    return (
      <div
        className="box has-background-white"
        style={{
          width: "350px",
          height: "250px",
          margin: "5px",
          display: "flex"
        }}
      >
        <div className="columns is-mobile" style={{ width: "100%" }}>
          <div className="column is-7 is-paddingless articleImageBox">
            <div className="imageWrapper">
              <img
                className="articleImage"
                src={this.props.articleInfo["imageURL"]}
                alt="articleImage"
              />
            </div>
          </div>
          <div className="column articleInfo is-paddingless ">
            <div className="content">
              <div style={{ fontSize: "13px", height: "50px" }}>
                {this.props.articleInfo["articleName"]}
              </div>
              <div style={{ fontSize: "11px", height: "50px" }}>
                {this.props.articleInfo["color"]}
              </div>
              <div style={{ fontSize: "13px", height: "20px" }}>
                <strong>{this.props.articleInfo["articleNumber"]}</strong>
              </div>
              <div style={{ height: "25px" }}>
                <span style={{ fontSize: "16px" }}>
                  <strong>
                    $
                    {netPrice(
                      this.props.articleInfo["retailPrice"],
                      this.props.articleInfo["priceCode"]
                    )}
                  </strong>
                </span>
                <span style={{ fontSize: "8px" }}> NET</span>
              </div>
              <div style={{ fontSize: "12px", height: "25px" }}>
                <strong>Sizes:</strong>{" "}
                {this.props.articleInfo["sizes"]
                  .split(",")[0]
                  .concat(
                    "-",
                    this.props.articleInfo["sizes"].split(",")[
                      this.props.articleInfo["sizes"].split(",").length - 1
                    ]
                  )}
              </div>
              <div style={{ fontSize: "12px", height: "20px" }}>
                {this.props.articleInfo["deliveryDate"].substr(1)}
              </div>
              <div>
                <button className="button is-white" onClick={this.showModal}>
                  <i className="fas fa-search-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleBox;
