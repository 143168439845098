import React from "react";
import NavBarGateKeeper from "./NavBarGateKeeper";
import { getServerPass } from "../base";

class GateKeeper extends React.Component {
  state = {
    passwordCorrect: true,
    serverPass: ""
  };

  passwordRef = React.createRef();

  attemptLogin = event => {
    event.preventDefault();

    if (this.passwordRef.current.value === this.state.serverPass) {
      sessionStorage.setItem("userPassword", this.passwordRef.current.value);
      this.props.history.push({
        pathname: "/articles",
        data: this.passwordRef.current.value
      });
    } else {
      this.setState({ passwordCorrect: false });
    }
  };

  componentDidMount() {
    getServerPass().then(serverPass => {
      this.setState({ serverPass: serverPass });
    });
  }

  render() {
    return (
      <React.Fragment>
        <NavBarGateKeeper />
        <div className="hero is-fullheight">
          <div className="hero-body">
            <div className="container" style={{ maxWidth: "512px" }}>
              <form
                className="field is-grouped center"
                onSubmit={this.attemptLogin}
              >
                <div className="control is-expanded">
                  <input
                    name="password"
                    autoCapitalize="none"
                    ref={this.passwordRef}
                    className={`input is-large ${
                      this.state.passwordCorrect ? null : "is-danger"
                    }`}
                    type="text"
                    required
                    placeholder="Password"
                  />
                </div>
                <div className="control">
                  <button className="button is-large is-dark" type="submit">
                    Enter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GateKeeper;
