import React from "react";

class LeftMenu extends React.Component {
  changeSelectedCollection = selectedCollection => {
    this.props.updateSelectedCollection(selectedCollection);
  };

  render() {
    return (
      <aside className="menu">
        <ul className="menu-list">
          {this.props.collections.map(collection => {
            return (
              <li key={collection}>
                <a
                  className={
                    this.props.selectedCollection === collection
                      ? "is-active"
                      : ""
                  }
                  onClick={() => this.changeSelectedCollection(collection)}
                >
                  {collection}
                </a>
              </li>
            );
          })}
        </ul>
      </aside>
    );
  }
}

export default LeftMenu;
